/*** DASHBOARD ***/
#dashboard{
  display: flex;
  align-items: stretch;
  flex-direction: row;
  justify-content: stretch;
  .sectionHello{
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    div{
      background-color: white;
      padding: 40px;
      width: 40%;
      border-radius: 5px;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      -ms-border-radius: 5px;
      -o-border-radius: 5px;
      h2{
        text-align: center;
      }
      h1{
        margin: 40px 0;
        font-size: 23px;
      }
      p{
        color: black;
        margin-bottom: 20px;
        font-weight: 500;
        b{
          height: 30px;
          width: 30px;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          color: white;
          border-radius: 5px;
          font-size: 17px;
          background: #314ab6;
          background: linear-gradient(90deg, #314ab6 0%, #5e8ae4 100%);
          margin-right: 10px;
        }
      }
      span{
        color: white;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        padding: 18px 36px;
        font-size: 18px;
        margin-top: 40px;
        b{
          margin-left: 5px;
          font-weight: 100;
        }
      }
    }
  }
  &.chatOpen{
    #container{
      display: none;
    }
    .chat{
      display: flex;
    }
  }
  #container{
    padding: 50px 15% 100px 15%;
    flex: 1;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: #202123;
    #premium{
      margin: 0;
      padding: 20px;
      position: relative;
      overflow: hidden;
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      color: white;
      border-radius: 5px;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      -ms-border-radius: 5px;
      -o-border-radius: 5px;
      gap: 40px;
      background: #454652;
      background: -webkit-gradient(linear, left top, left bottom, from(#121212), to(#1b1b1d));
      background: linear-gradient(90deg, #121212 0%, #1b1b1d 100%);
      border: 1px solid #2f2f2f;
      section{
        flex: 1;
        &:first-of-type{
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          .premiumContainerCenter{
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            div{
              flex: 1;
              display: flex;
              justify-content: flex-start;
              align-items: flex-start;
              flex-direction: column;
            }
            a{
              font-size: 14px;
              padding: 10px 16px;
              color: white;
              display: inline-flex;
            }
          }
        }
        h2{
          font-size: 22px;
          font-weight: 500;
          font-family: 'Playfair Display', serif;
        }
        p{
          font-size: 14px;
          display: inline-flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          margin-bottom: 10px;
          &:last-of-type{
            margin-bottom: 0px;
          }
          svg{
            height: 10px;
            width: 10px;
            width: fit-content;
            margin-right: 10px;
            path{
              fill: #5e8ae4;
            }
          }
        }
        span{
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          img{
            height: 20px;
            margin-right: 10px;
          }
        }
        h6{
          color: #ad4747;
          margin-top: 5px;
          display: none;
        }
        .inputContainer{
          display: flex;
          justify-content: center;
          align-items: stretch;
          width: 100%;
          margin-top: 20px;
          input{
            flex: 1;
            border-radius: 5px 0 0 5px;
            -webkit-border-radius: 5px 0 0 5px;
            -moz-border-radius: 5px 0 0 5px;
            -ms-border-radius: 5px 0 0 5px;
            -o-border-radius: 5px 0 0 5px;
            background-color: #292a36;
            border: 1px solid #2f2f2f;
            color: white;
            padding: 12px 8px;
          }
          p{
            padding: 0 15px;
            flex: 0 0 40%;
            border-radius: 0 5px 5px 0;
            -webkit-border-radius: 0 5px 5px 0;
            -moz-border-radius: 0 5px 5px 0;
            -ms-border-radius: 0 5px 5px 0;
            -o-border-radius: 0 5px 5px 0;
          }
        }
      }
      .separator{
        height: 100%;
        width: 1px;
        background-color: #2f2f2f;
      }
    }
    #premiumActivate{
      position: relative;
      overflow: hidden;
      display: none;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      color: #94856b;
      margin-bottom: -30px;
      h2{
        font-size: 14px;
      }
      span{
        display: flex;
        align-items: center;
        img{
          height: 15px;
          margin-right: 10px;
        }
      }
    }
    .container{
      transition: all 0.3s ease-out;
      -webkit-transition: all 0.3s ease-out;
      -moz-transition: all 0.3s ease-out;
      -ms-transition: all 0.3s ease-out;
      -o-transition: all 0.3s ease-out;
      opacity: 0.3;
      pointer-events: none;
      &.containerActive{
        opacity: 1;
        pointer-events: initial;
      }
      h2{
        color: white;
        flex-direction: column;
        gap: 5px;
        font-size: 30px;
        font-weight: 500;
        font-family: 'Playfair Display', serif;
        margin-top: 40px;
      }
      #containerInput{
        display: flex;
        margin-top: 40px;
        #containerInputFiles{
        background-color: #343541;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        color: white;
        border: 1px #44464b solid;
        border-radius: 5px;
        padding: 25px 50px;
        position: relative;
        flex: 1;
        margin-right: 40px;
        overflow: hidden;
          height: 100%;
          width: 100%;
          &:hover{
            .button_primary{
              background: #263b93;
              background: linear-gradient(90deg, #263b93 0%, #4f75c3 100%);
            }
          }
          p{
            text-align: center;
            span{
              opacity: 0.7;
              font-size: 12px;
              display: block;
              margin-top: 5px;
            }
          }
          .button_primary{
            margin-top: 20px;
            padding: 12px 24px;
          }
          input{
            height: 100%;
            width: 100%;
            z-index: 2;
            position: absolute;
            top: 0;
            cursor: pointer;
            opacity: 0;
          }
        }
        #containerInputURL{
          background-color: #343541;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          color: white;
          border: 1px #44464b solid;
          border-radius: 5px;
          padding: 25px 50px;
          position: relative;
          flex: 1;
          overflow: hidden;
          p{
            text-align: center;
            span{
              opacity: 0.7;
              font-size: 12px;
              display: block;
              margin-top: 5px;
            }
          }
          .containerInputURLContainer{
            display: flex;
            width: 100%;
            margin-top: 20px;
            height: 40px;
            input{
              flex: 1;
              border-radius: 5px 0 0 5px;
              -webkit-border-radius: 5px 0 0 5px;
              -moz-border-radius: 5px 0 0 5px;
              -ms-border-radius: 5px 0 0 5px;
              -o-border-radius: 5px 0 0 5px;
              background-color: #292a36;
              border: 1px solid #201e1e;
              color: white;
              padding: 8px;
            }
            .button_primary{
              height: 100%;
              flex: 0 0 30%;
              border-radius: 0 5px 5px 0;
              -webkit-border-radius: 0 5px 5px 0;
              -moz-border-radius: 0 5px 5px 0;
              -ms-border-radius: 0 5px 5px 0;
              -o-border-radius: 0 5px 5px 0;
            }
          }
          .loader{
            display: none;
            align-items: center;
            margin-top: 10px;
            width: 100%;
            p{
              display: inline-flex;
              font-size: 13px;
            }
            img{
              height: 13px;
              margin-right: 5px;
              animation: rotateLogo 3s infinite linear;
              -webkit-animation: rotateLogo 3s infinite linear;
            }
            
          }
        }
      }
      #tableData{
        width: 100%;
        display: flex;
        flex-direction: column;
        border-radius: 5px;
        border: 1px solid #525252;
        margin-top: 40px;
        overflow: hidden;
        p, i{
          flex: 1;
            padding: 0 3%;
          padding: 0 1%;
          text-align: center;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        #tableDataHeader{
          display: inline-flex;
          width: 100%;
          justify-content: space-around;
          align-items: center;
          color: white;
          background-color: #343541;
          padding: 12px 0;
          font-size: 13px;
        }
        .tableDataRow{
          color: white;
          display: inline-flex;
          width: 100%;
          flex: 1;
          align-items: center;
          padding: 12px 0;
          svg{
            height: 18px;
            width: auto;
            flex: 1;
            cursor: pointer;
            opacity: 0.6;
            &:hover{
              opacity: 1;
            }
          }
        }
        h3{
          color: white;
          display: flex;
          height: 50px;
          width: 100%;
          justify-content: center;
          align-items: center;
          opacity: 1;
          font-size: 14px;
        }
      }
      #startChat{
        display: inherit;
        margin: 40px auto 0 auto;
        padding: 14px 28px;
        font-size: 14px;
      }
      &#containerCreateChat{
        .containerName{
          background-color: #343541;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          color: white;
          border: 1px #44464b solid;
          border-radius: 5px;
          padding: 25px 50px;
          position: relative;
          flex: 1;
          overflow: hidden;
          margin-top: 20px;
          p{
            text-align: center;
            span{
              opacity: 0.7;
              font-size: 12px;
              display: block;
              margin-top: 5px;
            }
          }
          div{
            display: flex;
            width: 85%;
            margin-top: 20px;
            height: 40px;
            input{
              flex: 1;
              border-radius: 5px 0 0 5px;
              -webkit-border-radius: 5px 0 0 5px;
              -moz-border-radius: 5px 0 0 5px;
              -ms-border-radius: 5px 0 0 5px;
              -o-border-radius: 5px 0 0 5px;
              background-color: #292a36;
              border: 1px solid #201e1e;
              color: #d1d5db;
              padding: 8px;
            }
            .button_primary{
              height: 100%;
              flex: 0 0 30%;
              border-radius: 0 5px 5px 0;
              -webkit-border-radius: 0 5px 5px 0;
              -moz-border-radius: 0 5px 5px 0;
              -ms-border-radius: 0 5px 5px 0;
              -o-border-radius: 0 5px 5px 0;
              pointer-events: none;
              opacity: 0.5;
              &.active{
                pointer-events: initial;
                opacity: 1;
              }
            }
          }
          &:hover{
            h6{
              opacity: 1;
            }
          }
          h6{
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            opacity: 0;
            transition: all 0.2s ease-out;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #000000d3;
            z-index: 2;
            font-size: 13px;
          }
        }
      }
      &#containerData{
        #TokenCountMessage{
          padding: 12px;
          background-color: #3c435a;
          color: white;
          margin-top: 10px;
          border-radius: 5px;
          -webkit-border-radius: 5px;
          -moz-border-radius: 5px;
          -ms-border-radius: 5px;
          -o-border-radius: 5px;
          display: none;
          width: 100%;
          h4{
            font-size: 16px;
            font-weight: 500;
          }
          &.active{
            display: inline-block;
          }
        }
      }
      &.containerPremium{
        display: flex;
        justify-content: center;
        flex-direction: row;
        gap: 20%;
        color: white;
        border: 1px #44464b solid;
        border-radius: 5px;
        padding: 25px;
        position: relative;
        overflow: hidden;
        background: #314ab6;
        background: linear-gradient(90deg, #314ab6 0%, #1c222f 100%);				p{
          font-size: 12px;
          display: inline-flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          margin-bottom: 10px;
          color: white;
          i{
            margin-right: 5px;
            width: 16px;
            height: 16px;
            font-size: 8px;
            border: 1px solid white;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            border-radius: 100px;
          }
          &:last-of-type{
            margin-bottom: 0px;
          }
        }
        a{
          text-decoration: none;
          width: 100%;
          margin-top: 20px;
          color: black;
          background: white;
          border: 1px solid white;
          &:hover{
            background: transparent;
            color: white;
          }
        }
        h2{
          margin-bottom: 20px;
          font-size: 23px;
        }
        div:first-of-type{
          display: flex;
          justify-content: center;
          align-items: flex-start;
          flex-direction: column;
          flex: 0 0 35%;
        }
        div:last-of-type{
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          input{
            padding: 12px 10px;
            flex: 1;
            border-radius: 5px 0 0 5px;
            -webkit-border-radius: 5px 0 0 5px;
            -moz-border-radius: 5px 0 0 5px;
            -ms-border-radius: 5px 0 0 5px;
            -o-border-radius: 5px 0 0 5px;
            background-color: #292a36;
            border: 1px solid #201e1e;
            color: white;
          }
          p{
            flex: 0 0 30%;
            border-radius: 0 5px 5px 0;
            -webkit-border-radius: 0 5px 5px 0;
            -moz-border-radius: 0 5px 5px 0;
            -ms-border-radius: 0 5px 5px 0;
            -o-border-radius: 0 5px 5px 0;
            padding: 12px 10px;
          }
        }
      }
    }
  }
  .chat{
    flex: 1;
    position: relative;
    flex-direction: column;
    justify-content: space-between;
    background-color: #343541;
    display: none;
    overflow: hidden;
    height: calc(100vh - 77.5px);
    .chatContainer{
      height: calc(100vh - 200px);
      overflow: auto;
      padding-bottom: 60px;
    }
    #modelChatGPT{
      color: #bfc2c6;
      text-align: center;
      font-size: 14px;
      padding: 14px 0;
      background-color: #444653;
    }
    #chatInputContainer{
      bottom: 0;
      left: 0;
      right: 0;
      padding: 40px 20% 40px 20%;
      width: 100%;
      position: sticky;
      top: 0;
      background-color: #343541;
      box-shadow: 0 -20px 30px 30px #343541ed;
      #chatInput{
        width: 100%;
        position: relative;
        display: flex;
        textarea{
          background-color: #40414F;
          resize: none;
          width: 100%;
          padding: 14px 75px 14px 14px;
          height: 47px;
          min-height: 44px;
          max-height: 200px;
          border-radius: 5px;
          border: 0;
          font-size: 16px;
          color: white;
          box-shadow: 0 0 30px 0px rgba(0, 0, 0, 0.2);
          font-family: 'Roboto', sans-serif;
        }
        svg{
          position: absolute;
          right: 6px;
          bottom: 6px;
          margin: auto;
          cursor: pointer;
          color: white;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 35px;
          height: 35px;
          padding: 7px;
          background-color: #40414F;
          opacity: 0.2;
          pointer-events: none;
          transition: all 0.3s ease-out;
          -webkit-transition: all 0.3s ease-out;
          -moz-transition: all 0.3s ease-out;
          -ms-transition: all 0.3s ease-out;
          -o-transition: all 0.3s ease-out;
          border-radius: 5px;
          -webkit-border-radius: 5px;
          -moz-border-radius: 5px;
          -ms-border-radius: 5px;
          -o-border-radius: 5px;
          &:hover{
            background-color: #489969;
          }
        }
      }
    }
    .chatMessage{
      display: flex;
      flex-wrap: wrap;
      gap: 1.5rem;
      width: 100%;
      padding: 1.5rem 20%;
      &.chatGPT{
        background-color: #444653;
        .chatMessageLogo{
          background-color: #5BC083;
        }
        b{
          width: 8px;
          height: 16px;
          background-color: white;
          display: inline-flex;
          animation: blink 0.6s infinite linear;
          -webkit-animation: blink 0.6s infinite linear;
  }
      }
      &.chatUser{
        .chatMessageLogo{
          background-color: #8084A8;
        }
      }
      .chatMessageLogo{
        height: 30px;
        flex: 0 0 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        -o-border-radius: 5px;
        img{
          height: 20px;
          width: 20px;
        }
      }
      &.chatGPTOnboarding{
        p, ul{
          display: none;
        }
      }
      p{
        font-size: 16px;
        color: rgb(209, 213, 219);
        line-height: 28px;
        flex: 1;
      }
      em, a{
        flex-basis: 100%;
        margin-left: 55px;
        color: white;
      }
      em{
        font-style: initial;
        font-weight: 700;
        margin: 20px 0 10px 55px;
      }
      .listsMessage{
        li{
          flex-direction: column;
          align-items: start;
          text-align: left;
          margin-bottom: 20px;
          display: inherit;
          a{
            margin-left: 0;
          }
        }
      }
      ul{
        color: #d1d5db;
        flex-basis: 100%;
        margin-left: 55px;
        margin-top: -15px;
        padding-left: 0;
        li{
          list-style-type: none;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 10px;
          margin-bottom: 9px;
          span{
            background-color: #8084a8;
            padding: 10px;
            height: 30px;
            width: 30px;
            background-size: 15px;
            background-position: center;
            background-repeat: no-repeat;
            border-radius: 5px;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            -ms-border-radius: 5px;
            -o-border-radius: 5px;
          }
        }
      }
    }
  }
  .premiumInfo{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.95);
    display: none;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 2;
    color: white;
    h6{
      font-size: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      padding: 0 40px;
      img{
        height: 20px;
        margin-right: 10px;
      }
    }
    a{
      text-decoration: none;
      padding: 13px 26px;
      margin-top: 10px!important;
    }
    &.visible{
      display: flex!important;
    }
    &#premiumInfoMessage{
      color: white;
      background: rgb(0,0,0);
      background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
      h6{
        font-size: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        img{
          height: 20px;
          margin-right: 10px;
        }
      }
    }
  }
  #containerAPI{
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 25%;
    position: fixed;
    color: white;
    top: 77.5px;
    left: 0;
    z-index: 1;
    width: 100%;
    height: calc(100% - 77.5px);
    background-color: #000000ea;
    h2{
      font-size: 23px;
      font-weight: 500;
      letter-spacing: 1px;
      font-family: 'Playfair Display', serif;
      text-align: center;
    }
    #containerAPIKey{
      background-color: #343541;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      color: white;
      border: 1px #44464b solid;
      border-radius: 5px;
      padding: 25px 50px;
      position: relative;
      overflow: hidden;
      margin-top: 20px;
      width: 100%;
      a{
        text-align: center;
        color: white;
        text-decoration: none;
        b{
          font-weight: initial;
          text-decoration: underline;
        }
        span{
          opacity: 0.7;
          font-size: 12px;
          display: block;
          margin-top: 5px;
          text-decoration: none;
        }
      }
      div{
        display: flex;
        width: 85%;
        margin-top: 20px;
        height: 40px;
        input{
          flex: 1;
          border-radius: 5px 0 0 5px;
          -webkit-border-radius: 5px 0 0 5px;
          -moz-border-radius: 5px 0 0 5px;
          -ms-border-radius: 5px 0 0 5px;
          -o-border-radius: 5px 0 0 5px;
          background-color: #292a36;
          border: 1px solid #201e1e;
          color: white;
          padding: 8px;
        }
        .button_primary{
          height: 100%;
          flex: 0 0 30%;
          border-radius: 0 5px 5px 0;
          -webkit-border-radius: 0 5px 5px 0;
          -moz-border-radius: 0 5px 5px 0;
          -ms-border-radius: 0 5px 5px 0;
          -o-border-radius: 0 5px 5px 0;
        }
      }
      .containerAPIKeyInfo{
        padding: 8px 24px;
        font-size: 13px;
        color: white;
        margin-top: 20px;
        background-color: #3c435a;
        border-radius: 100px;
        -webkit-border-radius: 100px;
        -moz-border-radius: 100px;
        -ms-border-radius: 100px;
        -o-border-radius: 100px;
        position: absolute;
        bottom: 0px;
        opacity: 0;
        animation: fadeOut 2s forwards ease-out;
        -webkit-animation: fadeOut 2s forwards ease-out;
      }
    }
  }
  #checkBrowser{
    width: 100%;
    color: white;
    justify-content: center;
    align-content: center;
    padding-top: 40vh;
    .textEffect{
      margin-left: 5px;
    }
  }
}
#fileContainer{
  position: fixed;
  left: -100%;
  top: -100%;
  overflow: hidden;
  z-index: -1;
  width: 1px;
  height: 1px;
}
#mobileMessage{
  display: none;
  justify-content: center;
  align-items: center;
  color: white;
  flex-direction: column;
  padding: 200px 15%;
}

/*** LOADING ***/
#loading{
position: fixed;
z-index: 1000;
top: 0;
left: 0;
width: 100%;
height: 100vh;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
gap: 20px;
color: white;
background-color: black;
img{
  height: 40px;
  width: auto;
  animation: rotateLogo 3s infinite linear;
  -webkit-animation: rotateLogo 3s infinite linear;
}
h4{
  font-size: 20px;
  font-weight: 900;
}
}

/*** SIDEBAR LEFT ***/
#sidebarLeft{
  background-color: #202123;
  color: white;
  flex: 0 0 20%;
  padding: 10px;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-right: 1px solid #2f2f2f;
  height: calc(100vh - 77.5px);
  position: sticky;
  top: 77.5px;
  overflow: hidden;
  #sidebarLeftTop{
    width: 100%;
    p{
      display: none;
      flex-direction: column;
      width: 100%;
      font-size: 14px;
      font-weight: 900;
      gap: 5px;
      padding: 12px;
      color: white;
      border-radius: 5px;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      -ms-border-radius: 5px;
      -o-border-radius: 5px;
      margin-top: 10px;
      background-color: #ffffff12;
      b{
        font-size: 12px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 400;
        border-left: 1px solid #525252;
        padding-left: 10px;
      }
    }
  }
  h1{
    font-size: 14px;
    text-align: left;
    margin-bottom: 10px;
  }
  #sidebarLeftpremium{
    background-color: #121212;
    border-radius: 5px;
    padding: 15px;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    h2{
      font-size: 18px;
    }
    p{
      font-size: 12px;
      display: inline-flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;
      svg{
        margin-right: 5px;
        width: 16px;
        height: 16px;
        font-size: 8px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
      }
    }
    span{
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      img{
        height: 20px;
        margin-right: 10px;
      }
    }
    h6{
      color: #ad4747;
      margin-top: 5px;
      display: none;
    }
    .separator{
      background-color: #292929;
      height: 1px;
      width: 100%;
      display: inline-flex;
      margin: 20px 0;
    }
    div{
      display: flex;
      justify-content: center;
      align-items: stretch;
      width: 100%;
      margin-top: 20px;
      input{
        flex: 1;
        border-radius: 5px 0 0 5px;
        -webkit-border-radius: 5px 0 0 5px;
        -moz-border-radius: 5px 0 0 5px;
        -ms-border-radius: 5px 0 0 5px;
        -o-border-radius: 5px 0 0 5px;
        background-color: #292a36;
        border: 1px solid #201e1e;
        color: white;
        padding: 12px 8px;
      }
      p{
        padding: 0 15px;
        flex: 1;
        border-radius: 0 5px 5px 0;
        -webkit-border-radius: 0 5px 5px 0;
        -moz-border-radius: 0 5px 5px 0;
        -ms-border-radius: 0 5px 5px 0;
        -o-border-radius: 0 5px 5px 0;
      }
    }
    a{
      text-decoration: none;
      width: 100%;
      font-size: 14px;
      padding-top: 14px;
      padding-bottom: 14px;
    }
  }
  #sidebarLeftpremiumActivate{
    border-radius: 5px;
    border: 1px solid #94856b;
    padding: 15px;
    position: relative;
    overflow: hidden;
    display: none;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    color: #94856b;
    h2{
      font-size: 14px;
    }
    span{
      display: flex;
      align-items: center;
      img{
        height: 15px;
        margin-right: 10px;
      }
    }
  }
}
#sidebarRight{
  background-color: #202123;
  display: flex;
  color: white;
  flex: 0 0 16%;
  padding: 8px;
  background-image: url('../images/right.png');
  background-size: cover;
  p{
    height: calc(100% + 16px);
    width: calc(100% + 16px);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.7);
    margin: -8px;
  }
}