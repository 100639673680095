/*** INITIAL ***/
*, html, body {
  box-sizing: border-box;
  margin: 0;
}
*:focus {
  outline: none;
}
html {
  overflow-y: scroll;
  overflow-y: overlay;
}
body {
  font-family: 'Roboto', sans-serif;
  background-color: #202123;
}
::-webkit-scrollbar {
  background-color:transparent;
  width: 7px;
}
::-webkit-scrollbar-track {
  background-color:transparent;
  display: none;
}
::-webkit-scrollbar-thumb {
  background-color:#B8B9C3;
  border-radius:16px;
}
::-webkit-scrollbar-thumb:hover {
  background-color:#ffffff;
}
::-webkit-scrollbar-button {display:none}

.button_primary{
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  background: rgb(49,74,182);
  background: linear-gradient(90deg, rgba(49,74,182,1) 0%, rgba(94,138,228,1) 100%);
  padding: 8px;
  border-radius: 5px;
  transition: all 0.3s ease-out;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  color: white;
&:hover{
  background: rgb(38,59,147);
  background: linear-gradient(90deg, rgba(38,59,147,1) 0%, rgba(79,117,195,1) 100%);
}
}
@keyframes fadein{
  0%{
    opacity: 1;
  }
  100%{
    opacity: 0;
  }
}
@keyframes moveInFromBottom{
  0%{
    transform: translateY(100%);
    -webkit-transform: translateY(100%);
    -moz-transform: translateY(100%);
    -ms-transform: translateY(100%);
    -o-transform: translateY(100%);
  } 
  100%{
    transform: translateY(0%);
    -webkit-transform: translateY(0%);
    -moz-transform: translateY(0%);
    -ms-transform: translateY(0%);
    -o-transform: translateY(0%);
  }
}
@keyframes fadeOut{
  0%{
    bottom: 0px;
    opacity: 0;
  }
  20%{
    bottom: 9px;
    opacity: 1;
  }
  80%{
    bottom: 9px;
    opacity: 1;
  }
  100%{
    bottom: 0px;
    opacity: 0;
  }
}
@keyframes blink{
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}
@keyframes rotateLogo{
  0%{
    transform: rotateZ(0deg);
    -webkit-transform: rotateZ(0deg);
    -moz-transform: rotateZ(0deg);
    -ms-transform: rotateZ(0deg);
    -o-transform: rotateZ(0deg);
  }
  100%{
    transform: rotateZ(360deg);
    -webkit-transform: rotateZ(360deg);
    -moz-transform: rotateZ(360deg);
    -ms-transform: rotateZ(360deg);
    -o-transform: rotateZ(360deg);
  }
}

.textEffect{
  position: relative;
  z-index: 2;
  &:after{
      content: '';
      background: rgb(49,74,182);
      background: linear-gradient(90deg, rgba(49,74,182,1) 0%, rgba(94,138,228,1) 100%);
      width: 100%;
      height: 20px;
      position: absolute;
      bottom: 0px;
      left: 0;
      z-index: -1;
  }
}


navbar{
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  padding: 0 15%;
  margin: auto;
  border-bottom: 1px solid #2f2f2f;
  #logo{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 25px 0;
    color: white;
    text-decoration: none;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 1px;
    font-family: 'Playfair Display', serif;
    &:hover{
      svg{
        transform: rotateZ(45deg);
        -webkit-transform: rotateZ(45deg);
        -moz-transform: rotateZ(45deg);
        -ms-transform: rotateZ(45deg);
        -o-transform: rotateZ(45deg);
      } 
    }
    svg{
      height: 25px;
      width: 25px;
      transition: all 0.3s ease-out;
      -webkit-transition: all 0.3s ease-out;
      -moz-transition: all 0.3s ease-out;
      -ms-transition: all 0.3s ease-out;
      -o-transition: all 0.3s ease-out;
      path{
        stroke: white;
        fill: white;
      }
    }
  }
  #navbarMenu{
    display: flex;
    justify-content: center;
    align-items: stretch;
    a{
      color: white;
      padding: 0 25px;
      text-decoration: none;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      overflow: hidden;
      transition: all 0.3s ease-out;
      -webkit-transition: all 0.3s ease-out;
      -moz-transition: all 0.3s ease-out;
      -ms-transition: all 0.3s ease-out;
      -o-transition: all 0.3s ease-out;
      &:after{
        content: '';
        width: 100%;
        background-color: white;
        height: 3px;
        bottom: 0px;
        position: absolute;
        left: 0;
        opacity: 0;
        bottom: -3px;
        transition: all 0.3s ease-out;
        -webkit-transition: all 0.3s ease-out;
        -moz-transition: all 0.3s ease-out;
        -ms-transition: all 0.3s ease-out;
        -o-transition: all 0.3s ease-out;
      }
      &:hover{
        &:after{
          bottom: 0px;
          opacity: 1;
        }
      }
      &.navbarMenuHighlight{
        background: #314ab6;
        background: -webkit-gradient(linear, left top, right top, from(#314ab6), to(#5e8ae4));
        background: linear-gradient(90deg, #314ab6 0%, #5e8ae4 100%);
      }
    }
  }
}

footer{
  border-top: 1px solid #454652;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 100px 15%;
  margin: auto;
  color: white;
  gap: 40px;
  div{
    flex: 1;
    display: flex;
    flex-direction: column;
    &:first-of-type{
      flex: 1 1 50%;
    }
    span{
      margin-top: 20px;
      a{
        text-decoration: none!important;
        margin-right: 20px;
        &:hover{
          svg path{
            fill: #314ab6;
          }
        }
        svg{
          height: 30px;
          width: fit-content;
          path{
            fill: white;
          }
        }
      }
    }
    h2{
      font-size: 18px;
      .textEffect::after{
        height: 5px;
      }
    }
    h3{
      font-size: 15px;
      margin-top: 20px;
      font-weight: 200;
    }
    h4{
      font-size: 15px;
      margin-bottom: 20px;
    }
    a{
      color: white;
      text-decoration: none;
      margin-bottom: 20px;
      font-size: 15px;
      &:hover{
        text-decoration: underline;
      }
    }
  }
  h5{
    flex-basis: 100%;
    text-align: center;
    font-size: 9px;
    font-weight: 200;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
}