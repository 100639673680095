.sectionBannerH1{
    padding: 100px 15% 0 15%;
    color: white;
    text-align: center;
    overflow: hidden;
    background: #454652;
    background: linear-gradient(180deg, #202124 0%, #454652 100%);
    h1{
        font-size: 40px;
        font-weight: 500;
        letter-spacing: 1px;
        font-family: 'Playfair Display', serif;
    }
    h2{
        font-weight: 200;
        font-size: 16px;
        margin: 20px 0 25px 0;
        line-height: 22px;
    }
    .sectionBannerH1CTA{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        position: relative;
        width: fit-content;
        margin: auto;
        a{
            padding: 12px 24px;
            font-size: 16px;
        }
        p{
            display: flex;
            align-items: center;
            position: absolute;
            left: calc(100% + 15px);
            width: 200%;
            text-align: left;
            font-size: 18px;
            opacity: 1;
            font-family: 'Caveat', cursive;
            transform: rotateZ(-2deg) translateY(-2px);
            -webkit-transform: rotateZ(-2deg) translateY(-2px);
            -moz-transform: rotateZ(-2deg) translateY(-2px);
            -ms-transform: rotateZ(-2deg) translateY(-2px);
            -o-transform: rotateZ(-2deg) translateY(-2px);
            svg{
                display: none;
                height: 10px;
                width: 10px;
                margin-right: 7px;
                transform: rotateZ(180deg);
                -webkit-transform: rotateZ(180deg);
                -moz-transform: rotateZ(180deg);
                -ms-transform: rotateZ(180deg);
                -o-transform: rotateZ(180deg);
            }
        }
    }
    .sectionBannerH1User{
        margin-top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        img{
            border-radius: 100px;
            -webkit-border-radius: 100px;
            -moz-border-radius: 100px;
            -ms-border-radius: 100px;
            -o-border-radius: 100px;
            height: 30px;
            width: 30px;
            margin-right: -10px;
            border: 4px solid #2d2e33;
        }
        p{
            margin-left: 15px;
            font-weight: 200;
            font-size: 16px;
        }
    }
    #sectionBannerH1Demo{
        width: 100%;
        border-radius: 10px 10px 0 0;
        -webkit-border-radius: 10px 10px 0 0;
        -moz-border-radius: 10px 10px 0 0;
        -ms-border-radius: 10px 10px 0 0;
        -o-border-radius: 10px 10px 0 0;
        border: 1px solid #2f2f2f;
        margin-bottom: -100px;
        margin-top: 100px;
        -webkit-box-shadow: 0 0 40px 0px #232424;
        box-shadow: 0 0 40px 0px #232424;
        animation: 1s moveInFromBottom 0.5s  forwards ease-out;
        -webkit-animation: 1s moveInFromBottom 0.5s  forwards ease-out;
        transform: translateY(100%);
        -webkit-transform: translateY(100%);
        -moz-transform: translateY(100%);
        -ms-transform: translateY(100%);
        -o-transform: translateY(100%);
}
}
.sectionColumns{
    padding: 100px 15%;
    color: white;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 40px;
    background-color: #202124;
    h2{
        font-size: 40px;
        font-weight: 500;
        letter-spacing: 1px;
        font-family: 'Playfair Display', serif;
        flex-basis: 100%;
        text-align: center;
        margin-bottom: 40px;
    }
    div{
        flex: 1;
        img{
            height: 100px;
            width: auto;
            margin-bottom: 40px;
        }
        h3{
            letter-spacing: 1px;
            font-family: 'Playfair Display', serif;
            font-size: 20px;
            padding: 40px 0;
            border-top: 1px solid #454652;
            b{
                height: 30px;
                width: 30px;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                color: white;
                border-radius: 5px;
                font-size: 17px;
                background: #314ab6;
                background: linear-gradient(90deg, #314ab6 0%, #5e8ae4 100%);
                margin-right: 10px;
                font-family: 'Roboto', serif;
            }
        }
        h4{
            font-weight: 200;
            font-size: 16px;
            line-height: 22px;
        }
    }
}
.sectionUseCase {
    padding: 100px 15%;
    color: white;
    display: none;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    background-color: #161617;
    h2{
        font-size: 40px;
        font-weight: 500;
        letter-spacing: 1px;
        font-family: 'Playfair Display', serif;
        flex-basis: 100%;
        text-align: center;
        margin-bottom: 40px;
    }
    #sectionUseCaseContainer{
        overflow: auto;
        white-space: nowrap;
        padding-bottom: 10px;
        &::-webkit-scrollbar {
            height: 6px;
        }
        div{
            width: 300px;
            height: 350px;
            margin-right: 20px;
            display: inline-flex;
            padding: 50px;
            border-radius: 5px;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            -ms-border-radius: 5px;
            -o-border-radius: 5px;
            position: relative;
            img{
                position: absolute;
                right: 50px;
                bottom: 50px;
                height: 50px;
            }
            &:last-of-type{
                margin-right: 0;
            }
            h3{
                white-space: initial;
            }
        }
    }
}
.sectionStarted{
    padding: 100px 15%;
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    color: white;
    h2{
        font-size: 40px;
        font-weight: 500;
        letter-spacing: 1px;
        font-family: 'Playfair Display', serif;
        flex-basis: 100%;
        text-align: center;
    }
    h3{
        text-align: center;
        margin: 20px 0;
        font-weight: 200;
        font-size: 16px;
    }
    a{
        padding: 12px 24px;
        font-size: 16px;
    }
}
.sectionFAQ{
    padding: 100px 15%;
    color: white;
    background-color: #161617;
    h1{
        font-size: 40px;
        font-weight: 500;
        letter-spacing: 1px;
        font-family: 'Playfair Display', serif;
        margin-bottom: 40px;
    }
    .sectionFAQContain{
        border-bottom: 1px solid #454652;
        &.sectionFAQContainActive{
            div{
                &:first-of-type{
                    svg{
                        transform: rotateZ(0deg);
                        -webkit-transform: rotateZ(0deg);
                        -moz-transform: rotateZ(0deg);
                        -ms-transform: rotateZ(0deg);
                        -o-transform: rotateZ(0deg);
}
                }
                &:last-of-type{
                    display: flex;
                }
            }
        }
        div{
            color: white;
            &:first-of-type{
                display: flex;
                padding: 20px 0;
                justify-content: space-between;
                cursor: pointer;
                h3{
                    font-weight: 500;
                    font-size: 20px;
                }
                svg{
                    height: 20px;
                    width: auto;
                    transform: rotateZ(45deg);
                    -webkit-transform: rotateZ(45deg);
                    -moz-transform: rotateZ(45deg);
                    -ms-transform: rotateZ(45deg);
                    -o-transform: rotateZ(45deg);
                    transition: all 0.3s ease-out;
                    -webkit-transition: all 0.3s ease-out;
                    -moz-transition: all 0.3s ease-out;
                    -ms-transition: all 0.3s ease-out;
                    -o-transition: all 0.3s ease-out;
                    path{
                        fill: white;
                    }
                }
            }
            &:last-of-type{
                display: none;
                padding-bottom: 20px;
            }
        }
    }
}
.legalContainer{
    padding: 100px 15%;
    color: white;
    h2, h3{
        margin: 40px 0 ;
    }
}