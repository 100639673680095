/*** MOBILE ***/
@media (max-width: 640px) {
  .sectionBannerH1 .sectionBannerH1CTA p, navbar #navbarMenu, #checkBrowser, #dashboard #container{
    display: none!important;
  }
  navbar{
    justify-content: center;
  }
  footer{
    flex-direction: column;
    gap: 80px;
  }
  .sectionColumns{
    flex-direction: column;
    text-align: center;
    gap: 80px;
    div{
      img{
        margin-bottom: 20px;
      }
      h3{
        padding: 20px 0;
      }
    }
  }
  #mobileMessage{
    display: flex!important;
  }
}